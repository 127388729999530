document.querySelectorAll('.edit_ajax_form_btn').forEach(item => {
    item.addEventListener("click", function (e) {
        e.preventDefault();
        var url = $(this).data('url');
        $('.form-modal').html('');
        $('#modal-loader').show();
        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'html'
        })
            .done(function(data){
                $('.form-modal').html('');
                $('.form-modal').html(data);    // load response
                $('#modal-loader').hide();      // hide ajax loader
            })
            .fail(function(){
                $('#dynamic-content').html('<i class="glyphicon glyphicon-info-sign"></i> Something went wrong, Please try again...');
                $('#modal-loader').hide();
            });
    })
});